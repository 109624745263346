import { Button, Space, Table, Tag, Typography } from "antd";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

import dayjs from "dayjs";

export class RegistrationPayments extends React.Component<any, any> {
  state = {
    operations: []
  }

  async componentDidMount() {
    const { onLoadRegistrationPayments } = this.props;
    const result = await onLoadRegistrationPayments();
    this.setState({ operations: result.operations });
  }

  columns() {
    return [
      {
        title: 'Дата',
        dataIndex: ['date'],
        width: 120,
        sorter: (a: any, b: any) => {
          return (a.date > b.date ? -1 : 1)
        },
        render: (date: string) => dayjs(date).format('DD.MM.YYYY'),
      }, {
        title: 'Плательщик',
        ellipsis: true,
        dataIndex: ['payerName']
      }, {
        title: 'ИНН',
        width: 150,
        dataIndex: ['payerInn']
      }, {
        title: 'Счет',
        width: 200,
        dataIndex: ['payerAccount']
      }
    ];
  }

  render() {
    const { operations } = this.state;
    return <div>
      <Table
        columns={this.columns()}
        showSorterTooltip={false}
        dataSource={operations.sort((a: any, b: any) => a.date > b.date ? -1 : 1)}
        pagination={false}
        rowKey={(record: any) => `${record.date}-${record.id}`}
        expandable={{
          expandedRowRender: (record) => (
            <SyntaxHighlighter language="json">
              {JSON.stringify(record, null, 2)}
            </SyntaxHighlighter>
          ),
        }}
        style={{ width: '100%' }}
        // TODO: Косячит переключение в полноэкранный режим
        scroll={{ x: '100%', y: 'calc(100vh - 142px)' }}
      />
    </div>
  }
}