import React from 'react';
import { Button, Typography } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';

import { Account, accountValidationSchema } from '../form';
import { InputRow } from '../fields/InputRow';
import { InputPasswordRow } from '../fields/InputPasswordRow';
import { SelectRow } from '../fields/SelectRow';

const { Title } = Typography;

const UserInfo = () => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={2}>
          <Title level={3}>Данные пользователя</Title>
          <p>Эти данные будут использоваться вами для входа в личный кабинет.</p>
        </td>
      </tr>
      <InputRow label="Email пользователя" name="email" />
      <InputPasswordRow label="Пароль" name="password" />
      <InputPasswordRow label="Пароль (повторить)" name="passwordRepeat" />
    </tbody>
  </table>
);

const OrganizationInfo = () => {
  const taxOptions = [
    { label: 'без НДС', value: 'none' },
    { label: '0%', value: 'vat0' },
    { label: '10%', value: 'vat10' },
    { label: '20%', value: 'vat20' },
    { label: '10/110', value: 'vat110' },
    { label: '20/120', value: 'vat120' },
  ];

  return (
    <table style={{ maxWidth: '700px', width: '100%' }}>
      <tbody>
        <tr>
          <td colSpan={2}>
            <Title level={3}>Общие данные организации</Title>
            <p>Укажите общую информацию об организации, которую вы хотите подключить к системе.</p>
          </td>
        </tr>
        <InputRow label="Email Организации" name="organizationEmail" />
        <InputRow label="ИНН" name="inn" />
        <InputRow label="КПП (если применимо)" name="kpp" />
        <InputRow label="ОГРН(ИП)" name="ogrn" />
        <InputRow label="Полное название организации" name="fullName" />
        <InputRow label="Короткое название организации" name="name" />
        <SelectRow label="Ставка НДС" name="account.tax" options={taxOptions} />
        <InputRow label="Вебсайт (или группа в соц. сети)" name="siteUrl" />
        <tr>
          <td colSpan={2}>
            <p>
              Укажите ваше фирменное название на латинице. Допускаются только ЗАГЛАВНЫЕ БУКВЫ (CapsLock) в количестве
              от 3 до 12.
            </p>
          </td>
        </tr>
        <InputRow label="Название" name="billingDescriptor" maxLength={12} minLength={3} />
      </tbody>
    </table>
  );
};

const CeoInfo = () => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={2}>
          <Title level={3}>Сведения о руководителе</Title>
          <p>Укажите информацию о руководителе организации.</p>
        </td>
      </tr>
      <InputRow label="Фамилия" name="ceo.lastName" />
      <InputRow label="Имя" name="ceo.firstName" />
      <InputRow label="Отчество" name="ceo.middleName" />
    </tbody>
  </table>
);

const AddressesInfo = () => {
  const typeOptions = [
    {
      label: 'Юридический',
      value: 'legal',
    },
  ];
  const countryOptions = [
    {
      label: 'Россия',
      value: 'RUS',
    },
  ];
  return (
    <table style={{ maxWidth: '700px', width: '100%' }}>
      <tbody>
        <tr>
          <td colSpan={2}>
            <Title level={3}>Адрес организации</Title>
            <p>Укажите адрес организации.</p>
          </td>
        </tr>
        <SelectRow label="Тип" name="addresses[0].type" options={typeOptions} />
        <SelectRow label="Страна" name="addresses[0].country" options={countryOptions} />
        <InputRow label="Почтовый индекс" name="addresses[0].zip" />
        <InputRow label="Населенный пункт" name="addresses[0].city" />
        <InputRow label="Адрес" name="addresses[0].street" />
      </tbody>
    </table>
  );
};

const PhonesInfo = () => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={2}>
          <Title level={3}>Телефоны для связи</Title>
          <p>Укажите телефон организации. Этот телефон может быть передан клиенту для общения с поддержкой.</p>
        </td>
      </tr>
      <InputRow label="Номер" name="phones[0].phone" />
      <tr>
        <td colSpan={2}>
          <p>Укажите телефон контактного лица. Этот телефон может быть использован нами для связи с вами.</p>
        </td>
      </tr>
      <InputRow label="Номер" name="phones[1].phone" />
    </tbody>
  </table>
);

interface Props {
  onSubmit: (values: Account, formikBag: FormikHelpers<any>) => void;
  initialValues: Account;
}

export const MainInfoStep = ({ onSubmit, initialValues }: Props) => (
  <Formik
    initialValues={initialValues}
    validationSchema={accountValidationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form>
        <Title level={1}>Заявка на регистрацию</Title>
        <p>На этой странице вы можете заполнить заявку на присоединение к системе ClickPass.</p>
        <UserInfo />
        <OrganizationInfo />
        <CeoInfo />
        <AddressesInfo />
        <PhonesInfo />
        <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          Далее
        </Button>
      </Form>
    )}
  </Formik>
);

export default MainInfoStep;
