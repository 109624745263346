import React from 'react';
import Helmet from 'react-helmet';
import { Tabs } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './BalancePage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';
import { PrivateRoutes } from '../../constants/routes';
import { BalanceContainer } from './balance/BalanceContainer';
import { BankDetailsContainer } from './bank-details/BankDetailsContainer';

enum MenuKey {
  Balance = 'balance',
  Details = 'bankDetails',
}

const MenuRoutes: {
  [key: string]: string
} = {
  [MenuKey.Balance]: PrivateRoutes.BalanceInfo,
  [MenuKey.Details]: PrivateRoutes.BalanceBankDetails,
};

export const BalancePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: MenuKey.Balance,
      label: 'Баланс',
      children: <BalanceContainer />,
    },
    {
      key: MenuKey.Details,
      label: 'Банковские реквизиты',
      children: <BankDetailsContainer />,
    },
  ];

  let selectedKey: string = MenuRoutes.BalanceInfo;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <ApplicationLayout>
      <Helmet>
        <title>Партнеры</title>
      </Helmet>
      <div className="BalancePage">
        <Tabs
          activeKey={selectedKey}
          onChange={(tab) => {
            navigate(MenuRoutes[tab]);
          }}
          destroyInactiveTabPane
          className="clickpass-tabs"
          items={items}
        />
      </div>
    </ApplicationLayout>
  );
};

export default BalancePage;
