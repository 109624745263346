import { connect } from 'react-redux';

import { createProductGroup, updateProductGroup } from "../../../actions/productGroupsActions";

import { ProductGroupFormModal } from './ProductGroupFormModal';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onCreateProductGroup: (productGroup) => {
    return new Promise((resolve, reject) => {
      dispatch(createProductGroup(productGroup, resolve, reject));
      resolve()
    });
  },
  onUpdateProductGroup: (id, productGroup) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProductGroup(id, productGroup, resolve, reject));
      resolve()
    });
  },
})

export const ProductGroupFormModalContainer = connect(mapStateToProps, mapDispatchToProps)(ProductGroupFormModal);