import React from 'react';
import { Spin } from 'antd';

import './BankDetails.scss';

import { BankAccount } from '../../signup-page/form';

export type PartialBankAccount = Omit<BankAccount, 'details' | 'tax'>;

interface Props {
  details?: PartialBankAccount;
  loading: boolean;
}

export const BankDetails = ({ details, loading }: Props) => (
  <Spin spinning={loading} wrapperClassName="BankDetails">
    <div>
      Расчетный счет
    </div>
    <div>
      {details?.account}
    </div>
    <div>
      Корреспондентский счет
    </div>
    <div>
      {details?.korAccount}
    </div>
    <div>
      БИК
    </div>
    <div>
      {details?.bik}
    </div>
    <div>
      Название банка
    </div>
    <div>
      {details?.bankName}
    </div>
  </Spin>
);
