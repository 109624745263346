import React from 'react';
import Helmet from 'react-helmet';
import { Tabs } from 'antd';

import './PartnersPage.scss';

import ApplicationLayout from '../application/layouts/application-layout/ApplicationLayout';

import PartnersList from './partners-list/PartnersList';

import { RegistrationTasksContainer } from './registration-tasks/RegistrationTasksContainer';
import { PrivateRoutes } from '../../constants/routes';
import { VerifyTaskContainer } from './tasks/verify-task/VerifyTaskContainer';
import { ApproveTaskContainer } from './tasks/approve-tasks/ApproveTaskContainer';
import { BeneficiaryUpdateTaskContainer } from './tasks/beneficiary-update-task/BeneficiaryUpdateTaskContainer';
import { RegistrationPaymentsContainer } from './registration-payments/RegistrationPaymentsContainer';

class PartnersPage extends React.Component<any, any> {
  async componentDidMount() {
    const { onLoadPartners } = this.props;
    await onLoadPartners();
    this.forceUpdate();
  }

  render() {
    const { partners } = this.props;
    const { navigate, params: { tab } } = this.props;

    const items = [
      {
        key: 'partners-list',
        label: 'Партнеры',
        children: <PartnersList partners={partners} />,
      },
      {
        key: 'registration-payments',
        label: 'Оплата регистрации',
        children: <RegistrationPaymentsContainer />,
      },
      {
        key: 'registration-tasks',
        label: 'Задачи',
        children: <RegistrationTasksContainer />,
      },
    ];
    if (/verify-[a-z0-9-]+/.test(tab)) {
      const matches = tab.match(/verify-([a-z0-9-]+)/);
      items.push({
        key: tab,
        label: 'Верифицировать регистрацию',
        children: <VerifyTaskContainer workflowId={matches[1]} />,
      });
    }
    if (/beneficiary-update-[a-z0-9-]+/.test(tab)) {
      const matches = tab.match(/beneficiary-update-([a-z0-9-]+)/);
      items.push({
        key: tab,
        label: 'Исправить бенефициара',
        children: <BeneficiaryUpdateTaskContainer workflowId={matches[1]} />,
      });
    }
    if (/approve-[a-z0-9-]+/.test(tab)) {
      const matches = tab.match(/approve-([a-z0-9-]+)/);
      items.push({
        key: tab,
        label: 'Утвердить регистрацию',
        children: <ApproveTaskContainer workflowId={matches[1]} />,
      });
    }
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Партнеры</title>
        </Helmet>
        <div className="PartnersPage">
          <Tabs
            activeKey={tab ?? 'partners-list'}
            onChange={(tab) => {
              navigate(PrivateRoutes.Partners.replace(':tab', tab));
            }}
            destroyInactiveTabPane={true}
            className="clickpass-tabs"
            items={items}
          />
        </div>
      </ApplicationLayout>
    );
  }
}

export default PartnersPage;
