export const FETCH_REGISTRATION_TASKS: string = 'FETCH_REGISTRATION_TASKS';
export const fetchRegistrationTasks = (
  // eslint-disable-next-line no-unused-vars
  resolve = (value: unknown) => {},
  // eslint-disable-next-line no-unused-vars
  reject = (reason: unknown) => {},
) => ({
  type: FETCH_REGISTRATION_TASKS,
  payload: {},
  meta: {
    resolve,
    reject,
  },
});

export const FETCH_REGISTRATION_PAYMENTS: string = 'FETCH_REGISTRATION_PAYMENTS';
export const fetchRegistrationPayments = (
  // eslint-disable-next-line no-unused-vars
  resolve = (value: unknown) => {},
  // eslint-disable-next-line no-unused-vars
  reject = (reason: unknown) => {},
) => ({
  type: FETCH_REGISTRATION_PAYMENTS,
  payload: {},
  meta: {
    resolve,
    reject,
  },
});
