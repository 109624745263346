import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const UserInfo = ({ disabled }: Props) => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={4}>
          <Title level={3}>Данные пользователя</Title>
          <p>Эти данные будут использоваться вами для входа в личный кабинет.</p>
        </td>
      </tr>
      <InputRow disabled={disabled} label="Email пользователя" name="email" />
    </tbody>
  </table>
);

export default UserInfo;
