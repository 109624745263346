import React from 'react';
import { FormikHelpers } from 'formik';

import './SignupPage.scss';
import { MainInfoStep } from './steps/MainInfoStep';
import { Account, BankAccount, IpBeneficiary, UlBeneficiary } from './form';
import { SignupStep } from './context/signupReducer';
import { BankAccountStep } from './steps/BankAccountStep';
import BeneficiaryStep from './steps/BeneficiaryStep';

export interface Props {
  onUpdateStep: (step: SignupStep) => void;
  step: SignupStep;
  onSignUp: (values: any, formikBag: FormikHelpers<any>) => Promise<void>;
  accountValues: Account;
  bankAccountValues: BankAccount;
  beneficiaryValues: UlBeneficiary | IpBeneficiary;
  onAccountValueUpdate: (values: Account) => void;
  onBeneficiaryInfoUpdate: (values: UlBeneficiary | IpBeneficiary) => void;
  onBankAccountUpdate: (values: BankAccount) => void;
}

export const SignupPage = ({
  onUpdateStep,
  step,
  onSignUp,
  accountValues,
  bankAccountValues,
  beneficiaryValues,
  onAccountValueUpdate,
  onBeneficiaryInfoUpdate,
  onBankAccountUpdate,
}: Props) => (
  <div className="SignupPage">
    <div className="SignupPage-form">
      {
        step === SignupStep.MainInfo
        && (
          <MainInfoStep
            onSubmit={(values) => {
              onAccountValueUpdate(values);
              onUpdateStep(SignupStep.BeneficiaryInfo);
            }}
            initialValues={accountValues}
          />
        )
      }
      {
        step === SignupStep.BeneficiaryInfo
        && (
          <BeneficiaryStep
            onSubmit={(values) => {
              onBeneficiaryInfoUpdate(values);
              onUpdateStep(SignupStep.BankAccountInfo);
            }}
            initialValues={beneficiaryValues}
            onBack={(values) => {
              onBeneficiaryInfoUpdate(values);
              onUpdateStep(SignupStep.MainInfo);
            }}
          />
        )
      }
      {
        step === SignupStep.BankAccountInfo
        && (
          <BankAccountStep
            onSubmit={(values, formikBag) => {
              onBankAccountUpdate(values);
              onSignUp(values, formikBag);
            }}
            initialValues={bankAccountValues}
            onBack={(values) => {
              onBankAccountUpdate(values);
              onUpdateStep(SignupStep.BeneficiaryInfo);
            }}
          />
        )
      }
    </div>
  </div>
);

export default SignupPage;
