import { connect } from "react-redux";

import InspectorFormModal from "./InspectorFormModal";
import { createInspector, updateInspector } from "../../../actions/inspectorsActions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onCreateInspector: (inspector) => {
    return new Promise((resolve, reject) => {
      dispatch(createInspector(inspector, resolve, reject));
    });
  },
  onUpdateInspector: (id, inspector) => {
    return new Promise((resolve, reject) => {
      dispatch(updateInspector(id, inspector, resolve, reject));
    });
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectorFormModal);
