import React from 'react';
import { Button, Radio, Space } from 'antd';

import './MultiTicketCalendar.scss';

import { ProductItemStatus } from '../../../../constants/productItem';
import { getDateTimeString } from '../../../../utils/categoriesHelper';

interface State {
  selectedProductItem?: {
    specificationId: string;
    id: string;
  };
}

interface Props {
  onCancel: () => void;
  onConsume: (specificationId: string, productItemId?: string) => void;
  productItem: {
    item: {
      date: string;
      price: number;
      categoryId: string;
      productItemIds: string[];
    };
    productSpecificationId: string;
    metadata: {
      components: Array<{
        id: string;
        consumption: {
          productSpecifications: Array<{
            id: string;
            consumedDate: string | null;
            consumed: boolean;
            inspectorUserId: string;
          }>;
        };
        item: {
          categoryId: string;
          date: string;
          startTime: string;
          endTime?: string;
        };
        productSpecificationId: string;
        metadata: {
          productSpecification: {
            accountId: string;
          }
        }
        specification: {
          title: string;
          categories: Array<{
            id: string;
            name: string;
          }>;
        };
      }>
    }
    specification: {
      id: string;
      title: string;
      description: string;
      categories: Array<{
        id: string;
        components: Array<{
          categoryId: string;
          productSpecificationId: string;
          day: number;
          endTime?: string;
          startTime: string;
          quantity: number;
        }>
        name: string;
      }>
    };
    status: string;
  };
  accountId: string;
}

export class MultiTicketCalendar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedProductItem: undefined,
    };
    this.renderSpecificationSelection = this.renderSpecificationSelection.bind(this);
    this.getAvailableProductItems = this.getAvailableProductItems.bind(this);
    this.initSelectedSpecification = this.initSelectedSpecification.bind(this);
  }

  componentDidMount() {
    this.initSelectedSpecification();
  }

  componentDidUpdate() {
    this.initSelectedSpecification();
  }

  getAvailableProductItems() {
    const { productItem } = this.props;
    return productItem.metadata.components.reduce((acc, component) => {
      if (component.consumption.productSpecifications[0]?.consumed) {
        return acc;
      }
      const categoryName = component.specification.categories.find((category) => (
        category.id === component.item.categoryId
      ))?.name;
      acc.push({
        accountId: component.metadata.productSpecification.accountId,
        specificationId: component.productSpecificationId,
        title: `${component.specification.title} (${categoryName})\n${getDateTimeString(component)}`,
        id: component.id,
      });
      return acc;
    }, [] as Array<{
      id: string;
      specificationId: string;
      title: string;
      accountId: string;
    }>);
  }

  initSelectedSpecification() {
    const { selectedProductItem } = this.state;
    const availableProductItems = this.getAvailableProductItems();
    if (
      availableProductItems.length === 1
      && (
        (selectedProductItem && selectedProductItem.id !== availableProductItems[0].id)
        || !selectedProductItem
      )
    ) {
      this.setState({
        selectedProductItem: {
          specificationId: availableProductItems[0].specificationId,
          id: availableProductItems[0].id,
        },
      });
    }
  }

  renderSpecificationSelection() {
    const { selectedProductItem } = this.state;
    const availableProductItems = this.getAvailableProductItems();
    const { accountId } = this.props;
    return (
      <div className="MultiTicket-specificationSelect">
        <div className="MultiTicket-specificationSelectLabel">Выберите билет:</div>
        <Radio.Group
          onChange={(event) => {
            this.setState({
              selectedProductItem: {
                id: event.target.value,
                specificationId: (event.target as any)['data-specification-id'],
              },
            });
          }}
          value={selectedProductItem?.id}
        >
          <Space direction="vertical">
            {
              availableProductItems.map((productItem) => (
                <Radio
                  className="MultiTicketCalendar-radio"
                  key={productItem.id}
                  value={productItem.id}
                  data-specification-id={productItem.specificationId}
                  disabled={productItem.accountId !== accountId}
                >
                  {productItem.title}
                </Radio>
              ))
            }
          </Space>
        </Radio.Group>
      </div>
    );
  }

  render() {
    const { productItem, onCancel, onConsume } = this.props;
    const { selectedProductItem } = this.state;
    const categoryName = productItem.specification.categories.find((c) => (
      c.id === productItem.item.categoryId
    ))?.name;
    const title = `${productItem.specification.title} (${categoryName})`;
    const availableProductItems = this.getAvailableProductItems();

    return (
      <div>
        <b>
          {title}
          <br />
          {productItem.specification.description}
          <br />
          <small>
            {`${productItem.item.price} р.`}
          </small>
        </b>
        {
          availableProductItems.length > 1
          && this.renderSpecificationSelection()
        }
        <br />
        {
          availableProductItems.length === 1
          && (
            <>
              <div className="MultiTicketCalendar-radio">{availableProductItems[0]?.title}</div>
              <div>Не погашен</div>
            </>
          )
        }
        {availableProductItems.length === 0 && <div>Погашен</div>}
        {productItem.status === ProductItemStatus.CANCELED && <div>Оплата отменена</div>}
        {productItem.status === ProductItemStatus.REFUNDED && <div>Возмещен</div>}
        <br />
        <Space>
          {
            availableProductItems.length > 0
            && (
              <Button
                onClick={async () => {
                  if (selectedProductItem) {
                    await onConsume(selectedProductItem.specificationId, selectedProductItem.id);
                  }
                  if (availableProductItems.length === 1) {
                    await onConsume(productItem.productSpecificationId);
                  }
                  this.setState({ selectedProductItem: undefined });
                }}
                disabled={!selectedProductItem}
              >
                Погасить
              </Button>
            )
          }
          <Button onClick={onCancel}>Сканировать билет</Button>
        </Space>
      </div>
    );
  }
}

export default MultiTicketCalendar;
