import React from 'react';
import { Button, Space } from 'antd';

import { ProductItemStatus, ProductItemStatusText } from '../../../../constants/productItem';
import { getDateTimeString } from '../../../../utils/categoriesHelper';

interface Props {
  productItem: any;
  onCancel: () => void;
  onConsume: (id: string) => void;
}

export const BaseCalendar = ({ productItem, onCancel, onConsume }: Props) => {
  const categoryName = productItem.specification.categories
    .find((c: any) => c.id === productItem.item.categoryId).name;
  const title = `${productItem.specification.title} (${categoryName})`;
  const dateString = getDateTimeString(productItem);
  return (
    <div>
      <b>
        {title}
        <br />
        {dateString}
        <br />
        {productItem.specification.description}
        <br />
        <small>
          {productItem.item.price}
          {' '}
          р.
        </small>
      </b>
      <br />
      <div>{ProductItemStatusText[productItem.status]}</div>
      <br />
      <Space>
        {
          productItem.status === ProductItemStatus.CONFIRMED
          && (
            <Button onClick={() => {
              onConsume(productItem.productSpecificationId);
            }}
            >
              Погасить
            </Button>
          )
        }
        <Button onClick={onCancel}>Сканировать билет</Button>
      </Space>
    </div>
  );
};

export default BaseCalendar;
