import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';
import { SelectRow } from '../fields/SelectRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const AddressesInfo = ({ disabled }: Props) => {
  const typeOptions = [
    {
      label: 'Юридический',
      value: 'legal',
    },
  ];
  const countryOptions = [
    {
      label: 'Россия',
      value: 'RUS',
    },
  ];
  return (
    <table style={{ maxWidth: '700px', width: '100%' }}>
      <tbody>
        <tr>
          <td colSpan={2}>
            <Title level={3}>Адрес организации</Title>
            <p>Укажите адрес организации.</p>
          </td>
        </tr>
        <SelectRow label="Тип" name="addresses[0].type" options={typeOptions} disabled={disabled} />
        <SelectRow label="Страна" name="addresses[0].country" options={countryOptions} disabled={disabled} />
        <InputRow label="Почтовый индекс" name="addresses[0].zip" disabled={disabled} />
        <InputRow label="Населенный пункт" name="addresses[0].city" disabled={disabled} />
        <InputRow label="Адрес" name="addresses[0].street" disabled={disabled} />
      </tbody>
    </table>
  );
};
