import React from 'react';
import { Button, Space } from 'antd';
import { ProductItemStatus, ProductItemStatusText } from "../../../../constants/productItem";

export const Plain = ({ productItem, onCancel, onConsume }) => {
  return <div>
    <b>
      {productItem.specification.title}<br />
      {productItem.specification.description}<br />
      <small>{productItem.item.price} р.</small>
    </b>
    <br />
    <div>{ProductItemStatusText[productItem.status]}</div>
    <br />
    <Space>
      {
        productItem.status === ProductItemStatus.CONFIRMED &&
        <Button onClick={() => { onConsume(productItem.productSpecificationId) }}>Погасить</Button>
      }
      <Button onClick={onCancel}>Сканировать билет</Button>
    </Space>
  </div>;
}

export default Plain;
