import React from 'react';
import Helmet from 'react-helmet';
import { Tabs } from 'antd';

import './AccountPage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';

import { PrivateRoutes } from '../../constants/routes';

export class AccountPage extends React.Component<any, any> {
  async componentDidMount() {
    const { onLoadPartners } = this.props;
    await onLoadPartners();
    this.forceUpdate();
  }

  render() {
    const { navigate, params: { tab } } = this.props;

    const items = [
      {
        key: 'profile',
        label: 'Профиль Партнера',
        children: <div />,
      },
      {
        key: 'marketplace',
        label: 'Маркетплейс',
        children: <div />,
      },
    ];
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Профиль Партнера</title>
        </Helmet>
        <div className="ProfilePage">
          <Tabs
            activeKey={tab ?? 'profile'}
            onChange={(newTab: string) => {
              navigate(PrivateRoutes.Account.replace(':tab', newTab));
            }}
            destroyInactiveTabPane
            className="clickpass-tabs"
            items={items}
          />
        </div>
      </ApplicationLayout>
    );
  }
}
