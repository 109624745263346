import React from 'react';
import { Space } from 'antd';

import './Duration.scss';

import { SelectWrapper } from '../../../../../utils/form-helpers/antd-formik';

const minutesValues: number[] = [];
for (let i = 0; i < 60; i += 5) {
  minutesValues.push(i);
}

const hoursValues: number[] = [];
for (let i = 0; i < 24; i += 1) {
  hoursValues.push(i);
}

const daysValues: number[] = [];
for (let i = 0; i <= 31; i += 1) {
  daysValues.push(i);
}

interface Props {
  name: string;
}

export const Duration = ({ name }: Props) => (
  <div>
    <Space align="baseline">
      <SelectWrapper
        className="Duration-valueSelector"
        name={`${name}.days`}
        showSearch
      >
        {
          daysValues.map((value) => (
            <SelectWrapper.Option key={value} value={value}>
              {value}
            </SelectWrapper.Option>
          ))
        }
      </SelectWrapper>
      <span className="Duration-unitLabel">Дней</span>
      <SelectWrapper
        className="Duration-valueSelector"
        name={`${name}.hours`}
        showSearch
      >
        {
          hoursValues.map((value) => (
            <SelectWrapper.Option key={value} value={value}>
              {value}
            </SelectWrapper.Option>
          ))
        }
      </SelectWrapper>
      <span className="Duration-unitLabel">Часов</span>
      <SelectWrapper
        className="Duration-valueSelector"
        name={`${name}.minutes`}
        showSearch
      >
        {
          minutesValues.map((value) => (
            <SelectWrapper.Option key={value} value={value}>
              {value}
            </SelectWrapper.Option>
          ))
        }
      </SelectWrapper>
      <span className="Duration-unitLabel">Минут</span>
    </Space>
  </div>
);

export default Duration;
