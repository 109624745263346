import React from 'react';
import { useFormikContext } from 'formik';
import { Button, Divider } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import './CategoriesList.scss';

import { MultiTicketCalendarV1CategoryType, MultiTicketCalendarV1Type } from '../multiTicketCalendarV1';

import { Label } from '../label/Label';
import { CategoriesListItem } from './CategoriesListItem';

export interface CategoriesListProps {
  componentSpecifications: any[];
  categories: MultiTicketCalendarV1CategoryType[];
  onProductSpecificationDrawerShow: (categoryId: string) => void;
}

export const CategoriesList: React.FC<CategoriesListProps> = (props) => {
  const {
    categories,
    onProductSpecificationDrawerShow,
    componentSpecifications,
  } = props;
  const { setFieldValue } = useFormikContext<{ specification: MultiTicketCalendarV1Type }>();
  return (
    <div className="MultiTicketCalendarV1Categories">
      <Label title="Категории билетов" />
      <br />
      {categories.map((category, index) => (
        <React.Fragment key={category.id}>
          <CategoriesListItem
            componentSpecifications={componentSpecifications}
            category={category}
            index={index}
            onProductSpecificationDrawerShow={onProductSpecificationDrawerShow}
            onDeleteCategory={() => {
              const newValue = [...categories];
              newValue.splice(index, 1);
              setFieldValue('specification.categories', newValue);
            }}
            onDeleteComponent={(componentIndex: number) => {
              const newValue = [...(categories[index].components)];
              newValue.splice(componentIndex, 1);
              setFieldValue(`specification.categories[${index}].components`, newValue);
            }}
          />
          <Divider />
        </React.Fragment>
      ))}
      <Button
        onClick={() => {
          const newCategory = {
            id: uuidv4(),
            name: `Категория ${categories.length + 1}`,
            components: [],
          };
          setFieldValue(
            `specification.categories[${categories.length}]`,
            newCategory,
          );
        }}
      >
        Добавить категорию
      </Button>
    </div>
  );
};
