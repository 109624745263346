import React from 'react';
import { Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import './CategoriesListItem.scss';

import { InputWrapper, SelectField } from '../../../../../utils/form-helpers/antd-formik';

import { MultiTicketCalendarV1CategoryType, MultiTicketCalendarV1ComponentType } from '../multiTicketCalendarV1';

import { Label } from '../label/Label';
import { ScheduleType } from '../../base-calendar-product-form-modal/BaseCalendarProductFormModal';

export const eventTimes: string[] = [];
const endDate = dayjs().endOf('day');
let itTime = dayjs().startOf('day');
do {
  eventTimes.push(itTime.format('HH:mm'));
  itTime = itTime.add(15, 'minutes');
} while (itTime.isBefore(endDate));

export interface CategoryComponentsProps {
  categoryIndex: number;
  componentSpecifications: any[];
  components: MultiTicketCalendarV1ComponentType[];
  onDeleteComponent: (index: number) => void;
}

export const CategoryComponents = ({
  categoryIndex,
  components,
  componentSpecifications,
  onDeleteComponent,
}: CategoryComponentsProps) => {
  if (components.length === 0) {
    return null;
  }
  return (
    <table className="MultiTicketCalendarV1Category-components">
      <thead>
        <tr>
          <th> </th>
          <th>Впечатление</th>
          <th style={{ width: 70 }}>День программы</th>
          <th>Категория билета</th>
          <th style={{ width: 70 }}>Кол-во билетов в программе</th>
          <th style={{ width: 150 }}>Время посещения или период</th>
          <th style={{ width: 50 }}>Стоимость</th>
        </tr>
      </thead>
      <tbody>
        {
          components.map((component, index) => {
            const componentSpecification = componentSpecifications.find((specification) => (
              specification.id === component.productSpecificationId
            ));
            if (!componentSpecification) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td colSpan={6}>Загружаем информацию...</td>
                </tr>
              );
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index}>
                <td>
                  <Button
                    className="Categories-deleteItemButton"
                    size="small"
                    danger
                    shape="circle"
                    icon={<MinusOutlined />}
                    onClick={() => {
                      onDeleteComponent(index);
                    }}
                  />
                </td>
                <td>
                  {componentSpecification?.specification?.title}
                  <br />
                  <small>{component.productSpecificationId}</small>
                </td>
                <td>
                  <SelectField
                    name={`specification.categories[${categoryIndex}].components[${index}].day`}
                    options={[
                      { value: 1, label: 1 },
                    ]}
                  />
                </td>
                <td>
                  <SelectField
                    name={`specification.categories[${categoryIndex}].components[${index}].categoryId`}
                    options={componentSpecification?.specification.categories.map((category: any) => ({
                      value: category.id,
                      label: category.name,
                    }))}
                  />
                </td>
                <td>
                  <SelectField
                    name={`specification.categories[${categoryIndex}].components[${index}].quantity`}
                    options={[
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                      { value: 3, label: 3 },
                      { value: 4, label: 4 },
                    ]}
                  />
                </td>
                <td>
                  <SelectField
                    className="CategoriesListItem-timeSelect"
                    name={`specification.categories[${categoryIndex}].components[${index}].startTime`}
                  >
                    {
                      eventTimes.map((time) => (
                        <SelectField.Option key={time} value={time}>{time}</SelectField.Option>
                      ))
                    }
                  </SelectField>
                  {
                    componentSpecification?.specification.scheduleType === ScheduleType.TimePeriod && (
                      <SelectField
                        className="CategoriesListItem-timeSelect"
                        name={`specification.categories[${categoryIndex}].components[${index}].endTime`}
                      >
                        {
                          eventTimes.map((time) => (
                            <SelectField.Option key={time} value={time}>{time}</SelectField.Option>
                          ))
                        }
                      </SelectField>
                    )
                  }
                </td>
                <td>
                  {
                    componentSpecification?.specification?.categories
                      .find((category: any) => category.id === component.categoryId)
                      ?.price
                  }
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export interface CategoryListItemProps {
  index: number;
  componentSpecifications: any[];
  category: MultiTicketCalendarV1CategoryType;
  onProductSpecificationDrawerShow: (categoryId: string) => void;
  onDeleteCategory: () => void;
  onDeleteComponent: (index: number) => void;
}

export const CategoriesListItem = (props: CategoryListItemProps) => {
  const {
    index,
    category,
    componentSpecifications,
    onDeleteCategory,
    onDeleteComponent,
    onProductSpecificationDrawerShow,
  } = props;
  return (
    <div className="MultiTicketCalendarV1Category">
      <div className="MultiTicketCalendarV1Category-name">
        <Button
          className="Categories-deleteItemButton"
          size="small"
          danger
          shape="circle"
          icon={<MinusOutlined />}
          onClick={onDeleteCategory}
        />
        <InputWrapper
          name={`specification.categories[${index}].name`}
          antFieldProps={{
            label: (
              <Label title="Название" />
            ),
          }}
        />
      </div>
      <small>{category.id}</small>
      <CategoryComponents
        categoryIndex={index}
        componentSpecifications={componentSpecifications}
        components={category.components}
        onDeleteComponent={onDeleteComponent}
      />
      <Button
        className="MultiTicketCalendarV1Category-addComponent"
        onClick={() => onProductSpecificationDrawerShow(category.id)}
      >
        Добавить впечатление
      </Button>
    </div>
  );
};
