export const FETCH_INSPECTORS = 'FETCH_INSPECTORS';
export const fetchInspectors = (resolve, reject) => ({
  type: FETCH_INSPECTORS,
  meta: {
    resolve,
    reject
  }
});

export const SET_INSPECTORS = 'SET_INSPECTORS';
export const setInspectors = (inspectors) => ({
  type: SET_INSPECTORS,
  payload: {
    inspectors
  }
});

export const CREATE_INSPECTOR = 'CREATE_INSPECTOR';
export const createInspector = (inspector, resolve, reject) => ({
  type: CREATE_INSPECTOR,
  payload: {
    inspector
  },
  meta: {
    resolve,
    reject
  }
});

export const UPDATE_INSPECTOR = 'UPDATE_INSPECTOR';
export const updateInspector = (id, inspector, resolve, reject) => ({
  type: UPDATE_INSPECTOR,
  payload: {
    id,
    inspector
  },
  meta: {
    resolve,
    reject
  }
});

export const DELETE_INSPECTOR = 'DELETE_INSPECTOR';
export const deleteInspector = (id, resolve, reject) => ({
  type: DELETE_INSPECTOR,
  payload: {
    id
  },
  meta: {
    resolve,
    reject
  }
});