import React, { useState } from 'react';
import { useMount } from 'react-use';
import { BankDetails, PartialBankAccount } from './BankDetails';

export const BankDetailsContainer = () => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<PartialBankAccount>();
  useMount(async () => {
    try {
      await new Promise((resolve) => {
        setTimeout(() => resolve(true), 1000);
      });
      setDetails({
        account: '123',
        korAccount: '123',
        bik: '234234',
        bankName: '345345',
      });
    } finally {
      setLoading(false);
    }
  });
  return (
    <BankDetails
      loading={loading}
      details={details}
    />
  );
};
