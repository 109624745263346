import React from 'react';
import { Button } from 'antd';
import QrReader from 'react-qr-reader';
import { Helmet } from 'react-helmet';

import './ScannerPage.scss';
import { Checking } from './checking';

class ScannerPage extends React.Component {
  state = {
    step: 'initial',  // initial | scanning | checking | error
    productItem: null,
    error: null,
  }

  constructor(props) {
    super(props);
    this.onScanComplete = this.onScanComplete.bind(this);
    this.onScanError = this.onScanError.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onConsume = this.onConsume.bind(this);

    // setTimeout(() => {
    //   this.onCheck('e81c815b-8654-4d62-a2d6-94107e27eeab');
    // }, 1000);
  }

  onScanComplete(data) {
    if (data) {
      try {
        this.onCheck(data);
      } catch (e) {
        this.setState({
          step: 'error',
          error: `Не могу прочитать следующие данные:\n\n${data}`,
        });
      }
    }
  }

  onScanError(error) {
    this.setState({
      step: 'error',
      error: `Ошибка во время сканирования:\n\n${error}`,
    });
  }

  async onCheck(productItemId) {
    const { onRemoteProductItemCheck } = this.props;
    try {
      const productItem = await onRemoteProductItemCheck(productItemId);
      this.setState({
        step: 'checking',
        productItem,
      });
    } catch (error) {
      this.setState({
        step: 'error',
        error: `Ошибка во время проверки:\n\n${error}`,
      });
    }
  }

  async onConsume(productSpecificationId, productItemId) {
    const { onRemoteProductItemConsume, onRemoteProductItemCheck } = this.props;
    try {
      await onRemoteProductItemConsume(
        productItemId ?? this.state.productItem.id,
        productSpecificationId,
      );
      const productItem = await onRemoteProductItemCheck(this.state.productItem.id);
      this.setState({
        step: 'checking',
        productItem,
      });
    } catch (error) {
      this.setState({
        step: 'error',
        error: `Ошибка во время погашения:\n\n${error}`,
      });
    }
  }

  render() {
    return (
      <div className="ScannerPage">
        <Helmet>
          <title>Сканер</title>
        </Helmet>
        {this.renderInitialStep()}
        {this.renderScanning()}
        {this.renderChecking()}
        {this.renderError()}
      </div>
    );
  }

  renderInitialStep() {
    const { step } = this.state;
    if (step !== 'initial') {
      return null;
    }
    return (
      <Button onClick={() => {
        this.setState({ step: 'scanning' })
      }}>Сканировать билет</Button>
    )
  }

  renderScanning() {
    const { step } = this.state;
    if (step !== 'scanning') {
      return null;
    }
    return (
      <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateRows: '1fr auto 1fr' }}>
        <div style={{ background: 'rgba(0, 0, 0, 0.3)', height: '100%' }} />
        <QrReader
          delay={300}
          onError={this.onScanError}
          onScan={this.onScanComplete}
          style={{ width: '100%' }}
        />
        <div style={{ background: 'rgba(0, 0, 0, 0.3)', padding: '12px' }}>
          <Button onClick={() => this.setState({ step: 'initial' })}>Сканировать билет</Button>
        </div>
      </div>
    )
  }

  renderChecking() {
    const { step, productItem } = this.state;
    const { accountId } = this.props;
    if (step !== 'checking') {
      return null;
    }

    return (
      <Checking
        productItem={productItem}
        accountId={accountId}
        onCancel={() => this.setState({ step: 'initial' })}
        onConsume={this.onConsume}
      />
    )
  }

  renderError() {
    const { step, error } = this.state;
    if (step !== 'error') {
      return null;
    }
    return (
      <div>
        <pre>{error}</pre>
        <br />
        Убедитесь, правильный ли QR код вам показывают.
        <br />
        <br />
        <Button
          onClick={() => {
            this.setState({ step: 'scanning' });
          }}
        >
          Сканировать билет
        </Button>
      </div>
    );
  }
}

export default ScannerPage;
