import React, { useCallback, useState } from 'react';
import { Button, Form, Input, List, Space } from 'antd';

export interface ProductSearchFormProps {
  productSpecifications: any[]
  onSelect: (productSpecifications: any[]) => void
  onSearch: (searchQuery: string) => void
}

export const ProductSearchForm: React.FC<ProductSearchFormProps> = (props) => {
  const [ searchQuery, setSearchQuery ] = useState<string>('');
  const onChange = useCallback((input: any) => {
    setSearchQuery(input.target.value);
  }, []);

  return (
    <Form layout="vertical">
      <Form.Item label="Введите название мероприятия или организатора">
        <Input.Search
          enterButton="Найти"
          value={searchQuery}
          onChange={onChange}
          onSearch={props.onSearch}
        />
      </Form.Item>
      <List
        itemLayout="vertical"
        pagination={{ pageSize: 5 }}
        dataSource={props.productSpecifications}
        renderItem={(productSpecification) => (
          <div key={productSpecification.id}>
            <div>{productSpecification.specification.title}</div>
            <small>{productSpecification.id}</small>
            <div>{productSpecification.account.name}</div>
            <Button onClick={() => {
              props.onSelect(productSpecification);
            }}
            >
              Выбрать
            </Button>
          </div>
        )}
      />
    </Form>
  );
};
