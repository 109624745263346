import React from 'react';
import { Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessage, useFormikContext } from 'formik';

import './Categories.scss';

import { InputNumberWrapper, InputWrapper } from '../../../../../utils/form-helpers/antd-formik';
import { ProgramType } from '../BaseCalendarProductFormModal';

export const getDefaultCategory = (programType: ProgramType) => ({
  id: uuidv4(),
  name: programType === ProgramType.Individual ? 'За группу (4)' : 'Базовый',
  price: 100.0,
  maxParticipants: programType === ProgramType.Individual ? 4 : undefined,
});

export const Categories = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { programType } = values.specification;
  const isIndividual = programType === ProgramType.Individual;
  return (
    <>
      <div
        className={`Categories-categoriesSelect 
          ${programType === ProgramType.Individual ? 'threeColumns' : 'twoColumns'} 
        `}
      >
        <span>Название билета</span>
        {
          isIndividual
          && <span>Максимальное количество участников</span>
        }
        <span>Цена руб.</span>
        <span />
        {
          values.specification?.categories?.map((category: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <InputWrapper
                name={`specification.categories[${index}].name`}
                disabled={isIndividual}
              />
              {
                isIndividual
                && (
                  <InputNumberWrapper
                    name={`specification.categories[${index}].maxParticipants`}
                    min={1}
                    onChange={(maxParticipants) => {
                      setFieldValue(`specification.categories[${index}].name`, `За группу (${maxParticipants ?? ''})`);
                    }}
                  />
                )
              }
              <InputNumberWrapper name={`specification.categories[${index}].price`} />
              <Button
                className="Categories-deleteItemButton"
                size="small"
                danger
                shape="circle"
                icon={<MinusOutlined />}
                onClick={() => {
                  values.specification.categories.splice(index, 1);
                  setFieldValue('specification.categories', values.specification.categories);
                }}
              />
            </React.Fragment>
          ))
        }
      </div>
      <Button
        className="Categories-addCategoryButton"
        type="text"
        onClick={() => {
          setFieldValue(
            `specification.categories[${values.specification.categories.length}]`,
            getDefaultCategory(programType),
          );
        }}
      >
        {`${isIndividual ? 'Добавить группу' : 'Добавить категорию'}`}
      </Button>
      <ErrorMessage name="specification.categories">
        {(msg) => (
          typeof msg === 'string'
          && <div className="BaseCalendarProductFormModal-error">{msg}</div>
        )}
      </ErrorMessage>
    </>
  );
};

export default Categories;
