import React from 'react';
import { Button, Space } from 'antd';

import { ProductItemStatus, ProductItemStatusText } from "../../../../constants/productItem";

export const Base = ({ productItem, onCancel, onConsume }) => {
  const categoryName = productItem.specification.categories
    .find(c => c.id === productItem.item.categoryId).name;
  const title = `${productItem.specification.title} (${categoryName})`;
  return <div>
    <b>
      {title}<br />
      {productItem.specification.description}<br />
      <small>{productItem.item.price} р.</small>
    </b>
    <br />
    <div>{ProductItemStatusText[productItem.status]}</div>
    <br />
    <Space>
      {
        productItem.status === ProductItemStatus.CONFIRMED &&
        <Button onClick={() => { onConsume(productItem.productSpecificationId) }}>Погасить</Button>
      }
      <Button onClick={onCancel}>Сканировать билет</Button>
    </Space>
  </div>;
}

export default Base;
