import { connect } from 'react-redux';

import { MultiTicketProductFormModal } from './MultiTicketProductFormModal';
import { createProduct, fetchMultiTicketComponents, updateProduct } from '../../../../actions/productsActions';
import { productGroupsSelector } from '../../../../selectors/productsSelectors';

const mapStateToProps = (state) => ({
  productGroups: productGroupsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onTicketComponentsFetch: () => {
    return new Promise((resolve, reject) => {
      dispatch(fetchMultiTicketComponents(resolve, reject));
    });
  },
  onCreateProduct: (product) => {
    return new Promise((resolve, reject) => {
      dispatch(createProduct(product, resolve, reject));
    });
  },
  onUpdateProduct: (id, product) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProduct(id, product, resolve, reject));
    });
  },
});

export const MultiTicketProductFormModalContainer = (
  connect(mapStateToProps, mapDispatchToProps)(MultiTicketProductFormModal)
);

export default MultiTicketProductFormModalContainer;
