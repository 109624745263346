export enum BeneficiaryType {
  FlResident = 'FL_RESIDENT',
  FlNonResident = 'FL_NONRESIDENT',
  UlResident = 'UL_RESIDENT',
  UlNonresident = 'UL_NONRESIDENT',
  IpResident = 'IP_RESIDENT',
  IpNonresident = 'IP_NONRESIDENT',
  LiteContact = 'LITE_CONTACT'
}

export enum Citizenship {
  Russia = 'RU'
}

export enum RegistrationType {
  PostalAddress = 'POSTAL_ADDRESS',
  RegistrationAddress = 'REGISTRATION_ADDRESS',
  ResidenceAddress = 'RESIDENCE_ADDRESS',
  LegalEntityAddress = 'LEGAL_ENTITY_ADDRESS',
  OfficeOfForeignLegalEntityAddress = 'OFFICE_OF_FOREIGN_LEGAL_ENTITY_ADDRESS',
}

export enum DocumentType {
  Passport = 'PASSPORT',
  ForeignPassport = 'FOREIGN_PASSPORT',
  ForeignPassportOfForeignCitizens = 'FOREIGN_PASSPORT_OF_FOREIGN_CITIZENS',
  OfficialPassport = 'OFFICIAL_PASSPORT',
  DiplomaticPassport = 'DIPLOMATIC_PASSPORT',
  MigrationCard = 'MIGRATION_CARD',
  TemporaryResidencePermit = 'TEMPORARY_RESIDENCE_PERMIT',
  Visa = 'VISA',
  ResidencePermit = 'RESIDENCE_PERMIT',
  Contract = 'CONTRACT',
  ContractGpd = 'CONTRACT_GPD',
  Patent = 'PATENT'
}