import { SET_ACCOUNTS } from '../actions/accountsActions';

const initialState = {
  list: []
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS: {
      return {
        ...state,
        list: [
          ...action.payload.accounts
        ]
      };
    }

    default:
      return state;
  }
};

export default accounts;
