export const FETCH_REPORTS = 'FETCH_REPORTS';
export const fetchReports = (resolve, reject) => ({
  type: FETCH_REPORTS,
  meta: {
    resolve,
    reject
  }
});

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const downloadReport = (fileId, name, resolve, reject) => ({
  type: DOWNLOAD_REPORT,
  payload: {
    fileId,
    name
  },
  meta: {
    resolve,
    reject
  }
})