import React from 'react';

import { SchemaType } from '../../../constants/schema';

import { PlainProductFormModalContainer } from './plain-product-form-modal';
import { BaseProductFormModalContainer } from './base-product-form-modal/BaseProductFormModalContainer';
import { MultiTicketComponentProductFormModalContainer } from './multi-ticket-component-product-form-modal';
import { MultiTicketProductFormModalContainer } from './multi-ticket-product-form-modal';
import {
  BaseCalendarProductFormModalContainer,
} from './base-calendar-product-form-modal/BaseCalendarProductFormModalContainer';
import {
  MultiTicketCalendarProductFormModalContainer,
} from './multi-ticket-calendar-product-form-modal/MultiTicketCalendarProductFormModalContainer';

const productFormMap = {
  // Deprecated
  [SchemaType.Plain]: PlainProductFormModalContainer,
  [SchemaType.Base]: BaseProductFormModalContainer,
  [SchemaType.MultiTicketComponent]: MultiTicketComponentProductFormModalContainer,
  [SchemaType.MultiTicket]: MultiTicketProductFormModalContainer,
  // Current
  [SchemaType.BaseCalendar]: BaseCalendarProductFormModalContainer,
  [SchemaType.MultiTicketCalendar]: MultiTicketCalendarProductFormModalContainer,
};

interface ProductFormProps {
  schemaType: string

  [key: string]: any
}

export const ProductFormModal = ({ schemaType, ...other }: ProductFormProps) => {
  const Component = productFormMap[schemaType];
  return (
    <Component
      onCancel={other?.onCancel}
      onComplete={other?.onComplete}
      product={other?.product}
      {...other}
    />
  );
};
