import { ProductSpecificationInterface } from '../components/products-page/ProductsPage';

export const FETCH_PRODUCTS: string = 'FETCH_PRODUCTS';
export const fetchProducts = (
  // eslint-disable-next-line no-unused-vars
  resolve = (value: unknown) => {},
  // eslint-disable-next-line no-unused-vars
  reject = (reason: unknown) => {},
) => ({
  type: FETCH_PRODUCTS,
  meta: {
    resolve,
    reject,
  },
});

export const FETCH_PRODUCT: string = 'FETCH_PRODUCT';
export const fetchProduct = (
  productId: string,
  // eslint-disable-next-line no-unused-vars
  resolve = ({ product }: { product: ProductSpecificationInterface }) => {},
  // eslint-disable-next-line no-unused-vars
  reject = (reason: unknown) => {},
) => ({
  type: FETCH_PRODUCT,
  payload: {
    productId,
  },
  meta: {
    resolve,
    reject,
  },
});

export const SET_PRODUCTS: string = 'SET_PRODUCTS';
export const setProducts = (products: any[]) => ({
  type: SET_PRODUCTS,
  payload: {
    products,
  },
});

export const CREATE_PRODUCT: string = 'CREATE_PRODUCT';
export const createProduct = (
  product: any,
  // eslint-disable-next-line no-unused-vars
  resolve = (value: unknown) => {},
  // eslint-disable-next-line no-unused-vars
  reject = (reason: unknown) => {},
) => ({
  type: CREATE_PRODUCT,
  payload: {
    product,
  },
  meta: {
    resolve,
    reject,
  },
});

export const FETCH_MULTI_TICKET_COMPONENTS: string = 'FETCH_MULTI_TICKET_COMPONENTS';
export const fetchMultiTicketComponents = (resolve = () => {}, reject = () => {}) => ({
  type: FETCH_MULTI_TICKET_COMPONENTS,
  payload: {},
  meta: {
    resolve,
    reject,
  },
});

export const UPDATE_PRODUCT: string = 'UPDATE_PRODUCT';
export const updateProduct = (
  id: string,
  product: any,
  // eslint-disable-next-line no-unused-vars
  resolve = (value: unknown) => {},
  // eslint-disable-next-line no-unused-vars
  reject = (reason: unknown) => {},
) => ({
  type: UPDATE_PRODUCT,
  payload: {
    id,
    product,
  },
  meta: {
    resolve,
    reject,
  },
});

// PRODUCT ITEMS

export const CHECK_PRODUCT_ITEM: string = 'CHECK_PRODUCT_ITEM';
export const checkProductItem = (productItem: any, resolve = () => {}, reject = () => {}) => ({
  type: CHECK_PRODUCT_ITEM,
  payload: {
    productItem,
  },
  meta: {
    resolve,
    reject,
  },
});

export const CONSUME_PRODUCT_ITEM: string = 'CONSUME_PRODUCT_ITEM';
export const consumeProductItem = (
  id: string,
  productSpecificationId: string,
  resolve = () => {},
  reject = () => {},
) => ({
  type: CONSUME_PRODUCT_ITEM,
  payload: {
    productItem: {
      id,
    },
    productSpecification: {
      id: productSpecificationId,
    },
  },
  meta: {
    resolve,
    reject,
  },
});
