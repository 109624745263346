import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';
import { SelectRow } from '../fields/SelectRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const OrganizationInfo = ({ disabled }: Props) => {
  const taxOptions = [
    { label: 'без НДС', value: 'none' },
    { label: '0%', value: 'vat0' },
    { label: '10%', value: 'vat10' },
    { label: '20%', value: 'vat20' },
    { label: '10/110', value: 'vat110' },
    { label: '20/120', value: 'vat120' },
  ];
  return (
    <table style={{ maxWidth: '700px', width: '100%' }}>
      <tbody>
        <tr>
          <td colSpan={4}>
            <Title level={3}>Общие данные организации</Title>
            <p>Укажите общую информацию об организации, которую вы хотите подключить к системе.</p>
          </td>
        </tr>
        <InputRow label="Email Организации" name="organizationEmail" disabled={disabled} />
        <InputRow label="ИНН" name="inn" disabled={disabled} />
        <InputRow label="КПП (если применимо)" name="kpp" disabled={disabled} />
        <InputRow label="ОГРН(ИП)" name="ogrn" disabled={disabled} />
        <InputRow label="Полное название организации" name="fullName" disabled={disabled} />
        <InputRow label="Короткое название организациии" name="name" disabled={disabled} />
        <SelectRow label="Ставка НДС" name="account.tax" options={taxOptions} disabled={disabled} />
        <InputRow label="Вебсайт (или группа в соц. сети)" name="siteUrl" disabled={disabled} />
        <tr>
          <td colSpan={4}>
            <p>
              Укажите ваше фирменное название на латинице. Допускаются только ЗАГЛАВНЫЕ БУКВЫ (CapsLock) в количестве
              от 3 до 12.
            </p>
          </td>
        </tr>
        <InputRow label="Название в СМС" name="billingDescriptor" maxLength={12} minLength={3} disabled={disabled} />
      </tbody>
    </table>
  );
};
