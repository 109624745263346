export interface RulePayload {
  actor: { [key: string]: any };
  target: { [key: string]: any };
}

const ruleToClass: Record<string, any> = {};

export interface RbacRuleFactoryInterface {
  createRule: (rule: string) => { execute: ({ actor, target }: RulePayload) => boolean };
}

export default class RbacRuleFactory implements RbacRuleFactoryInterface {
  constructor() {}

  createRule(ruleName: string) {
    if (ruleToClass[ruleName]) {
      return new ruleToClass[ruleName]();
    } else {
      return null;
    }
  }
}
