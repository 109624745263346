import React, { Dispatch } from 'react';
import { thunk } from 'redux-thunk';
import { createReducer } from 'react-use';

import { SignupContextState, initialState, signupReducer } from './signupReducer';

const useThunkReducer = createReducer<any, SignupContextState>(thunk as any);

const SignupStateContext = React.createContext<SignupContextState>(initialState({}));
export const SignupDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState?: Partial<SignupContextState>,
}

const SignupProvider: React.FC<Props> = ({ children, initState = {} }) => {
  const [state, dispatch] = useThunkReducer(signupReducer, initialState(initState));

  return (
    <SignupStateContext.Provider value={state}>
      <SignupDispatchContext.Provider value={dispatch}>
        {children}
      </SignupDispatchContext.Provider>
    </SignupStateContext.Provider>
  );
};

function useSignupState() {
  const context = React.useContext(SignupStateContext);
  if (context === undefined) {
    throw new Error('useSignupState must be used within a SignupStateContext');
  }
  return context;
}

function useSignupDispatch() {
  const context = React.useContext(SignupDispatchContext);
  if (context === undefined) {
    throw new Error('useSignupDispatch must be used within a SignupDispatchContext');
  }
  return context;
}

export {
  SignupProvider,
  useSignupState,
  useSignupDispatch,
};
