import * as yup from 'yup';
import { InferType, object } from 'yup';

// NOTICE: This is the same file as can be found in the backend
import { SchemaType } from '../../../../constants/schema';

export const multiTicketCalendarV1ComponentSchema = yup.object({
  productSpecificationId: yup.string().required('ID компонента должно быть заполнено.'),
  categoryId: yup.string().required('ID категории в компоненте должны быть заполнено.'),
  // Поля ниже - часть паттерна поиска всего мультибилета
  day: yup.number().integer().positive().min(1)
    .required('День программы должен быть заполнен'),
  quantity: yup.number().integer().positive().min(1)
    .required('Количество билетов обязательно к заполнению'),
  startTime: yup.string().required('Время начала обязательно к заполнению'),
  endTime: yup.string().optional(),
});

export type MultiTicketCalendarV1ComponentType = InferType<typeof multiTicketCalendarV1ComponentSchema>;

export const multiTicketCalendarV1CategorySchema = object({
  id: yup.string().required('ID категории в компоненте должны быть заполнено.'),
  name: yup.string().required('Имя категории обязательно к заполнению.'),
  components: yup.array()
    .of(multiTicketCalendarV1ComponentSchema)
    .min(1, 'Как минимум один компонент должен быть в категории.')
    .required('Компоненты категории обязательны к заполнению.'),
});

export type MultiTicketCalendarV1CategoryType = InferType<typeof multiTicketCalendarV1CategorySchema>;

export const multiTicketCalendarV1Schema = yup.object({
  type: yup.string()
    .oneOf([ `${SchemaType.MultiTicketCalendar}` ])
    .required('Тип схемы указан неверно.'),
  version: yup.number()
    .oneOf([ 1 ])
    .required('Version of the specification is required.'),
  title: yup.string().required('Product name is required.'),
  description: yup.string().required('Product description is required.'),

  included: yup.string().optional(),
  notIncluded: yup.string().optional(),

  startLocationId: yup.string().required('Start location is required'),
  startAddress: yup.string().required('Start address is required'),

  endLocationId: yup.string().required('End location is required'),
  endAddress: yup.string().required('Start address is required'),

  difficulty: yup.number().oneOf([ 1, 2, 3, 4, 5 ]).default(1),

  photos: yup.array().of(yup.string()).default([]),

  categories: yup.array()
    .of(multiTicketCalendarV1CategorySchema)
    .min(1, 'At least one category is required.')
    .required('At least one category is required.'),
})
  .required('Specification is not defined.');

export type MultiTicketCalendarV1Type = InferType<typeof multiTicketCalendarV1Schema>;

export const multiTicketCalendarV1Record = yup.object({
  id: yup.string().required(),
  specification: multiTicketCalendarV1Schema,
  metadata: yup.object({
    account: yup.object(),
    startLocation: yup.object(),
    endLocation: yup.object(),
    components: yup.array().of(yup.object()),
  }),
})
  .required();

export type multiTicketCalendarV1RecordType = InferType<typeof multiTicketCalendarV1Record>;
