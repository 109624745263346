import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { fetchRegistrationTasks } from "../../../actions/registrationActions";

import RegistrationTasks from "./RegistrationTasks";

const RegistrationTasksContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <RegistrationTasks
      navigate={navigate}
      onLoadRegistrationTasks={() => {
        return new Promise((resolve, reject) => {
          dispatch(fetchRegistrationTasks(resolve, reject));
        });
      }}
    />
  )
};

export {
  RegistrationTasksContainer
}