import React from 'react'
import InspectorsPage from './InspectorsPage';
import { connect } from 'react-redux';
import { inspectorsSelector } from '../../selectors/inspectorsSelectors';
import { fetchInspectors, deleteInspector } from '../../actions/inspectorsActions';

const mapStateToProps = (state) => ({
  inspectors: inspectorsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  onLoadInspectors: () => {
    return new Promise((resolve, reject) => {
      dispatch(fetchInspectors(resolve, reject));
    });
  },
  onDeleteInspector: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteInspector(id, resolve, reject));
    });
  }
});

export const InspectorsPageContainer = connect(mapStateToProps, mapDispatchToProps)(InspectorsPage);

export default InspectorsPageContainer;

