import { SET_PRODUCTS } from '../actions/productsActions';

const initialState = {
  list: []
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        list: [
          ...action.payload.products
        ]
      };
    }

    default:
      return state;
  }
};

export default products;
