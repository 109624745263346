class ConsoleLoggerService {
  info(message) {
    console.log(`[${new Date().toString()}] [INFO] ${message}`);
  }

  warn(message) {
    console.warn(`[${new Date().toString()}] [WARN] ${message}`);
  }

  error(message) {
    console.error(`[${new Date().toString()}] [ERROR] ${message}`);
  }
}

export default ConsoleLoggerService;
