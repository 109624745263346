import React, { useCallback } from 'react';
import { InputNumber as _InputNumber, InputNumberProps as _InputNumberProps } from 'antd';
import { FieldProps } from 'formik';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { makeField } from './makeField';

type InputNumberProps = FieldProps & _InputNumberProps;
type InputNumberFieldProps = FormikFieldProps & _InputNumberProps;

export const InputNumber = React.forwardRef((
  {
    field,
    form,
    onChange: $onChange,
    onBlur: $onBlur,
    ...restProps
  }: InputNumberProps,
  ref: React.Ref<HTMLInputElement>,
) => {
  const { value, onBlur, name } = field;
  const { setFieldValue } = form;
  const handleChange = useCallback((numberValue: any) => {
    setFieldValue(name, numberValue);
    if ($onChange) {
      $onChange(numberValue);
    }
  }, [setFieldValue, name, $onChange]);
  return (
    <_InputNumber
      ref={ref}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={(event) => {
        onBlur(event);
        if ($onBlur) {
          $onBlur(event);
        }
      }}
      {...restProps}
    />
  );
});

InputNumber.displayName = 'InputNumber';
export const InputNumberField = React.forwardRef((
  {
    name,
    validate,
    fast,
    ...restProps
  }: InputNumberFieldProps,
  ref: React.Ref<HTMLInputElement>,
) => (
  <Field name={name} validate={validate} fast={fast}>
    {(fieldProps: any) => (
      <InputNumber ref={ref} {...fieldProps} {...restProps} />
    )}
  </Field>
));

InputNumberField.displayName = 'InputNumberField';

export const InputNumberWrapper = makeField<_InputNumberProps>(InputNumber);
