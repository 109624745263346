import React, { useState } from 'react';
import { useMount } from 'react-use';
import * as Yup from 'yup';
import { notification } from 'antd';
import { Balance } from './Balance';
import { submitValidate } from '../../../utils/form-helpers/antd-formik';

const validationSchema = (currentBalance: number) => Yup.object().shape({
  amount: Yup.number()
    .required()
    .min(1, 'Платеж должен быть больше 1 рубля')
    .max(currentBalance, 'Значение превышает баланс'),
});

export const BalanceContainer = () => {
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState<number>();
  useMount(async () => {
    try {
      await new Promise((resolve) => {
        setTimeout(() => resolve(true), 1000);
      });
      setBalance(1000);
    } finally {
      setLoading(false);
    }
  });
  return (
    <Balance
      loading={loading}
      balance={balance}
      onSubmit={async (values, formikBag) => {
        if (!balance || !submitValidate(values, validationSchema(balance), formikBag)) {
          return;
        }
        try {
          await new Promise((resolve) => {
            setTimeout(() => resolve(true), 1000);
          });
          // await ApiCall(values.amount)
          notification.success({ message: 'Перевод успешно выполнен' });
        } catch {
          notification.error({ message: 'Перевод успешно выполнен' });
        }
      }}
    />
  );
};
