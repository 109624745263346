import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

/** Saga Middleware */
const sagasMiddleware = createSagaMiddleware();

/** Create middlewares for redux */
const composeMiddlewares = applyMiddleware(sagasMiddleware);

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    }) : compose;

/** Create redux store */
export default createStore(
  rootReducer,
  {},
  composeEnhancers(composeMiddlewares),
);

/** run saga watchers */
sagasMiddleware.run(rootSaga);
