import React from 'react';

import { SchemaType } from '../../../constants/schema';

import { Base } from './base';
import { BaseCalendar } from './base-calendar';
import { Plain } from './plain';
import { MultiTicket } from './multi-ticket';
import { MultiTicketCalendar } from './multi-ticket-calendar/MultiTicketCalendar';

export const productRowMap = {
  [SchemaType.Plain]: Plain,
  [SchemaType.Base]: Base,
  [SchemaType.BaseCalendar]: BaseCalendar,
  [SchemaType.MultiTicket]: MultiTicket,
  [SchemaType.MultiTicketCalendar]: MultiTicketCalendar,
};

export class Checking extends React.Component {
  render() {
    const { productItem, accountId, onCancel, onConsume } = this.props;
    const Component = productRowMap[productItem.specification.type];
    return (
      <Component
        productItem={productItem}
        accountId={accountId}
        onCancel={onCancel}
        onConsume={onConsume}
      />
    );
  }
}

export default Checking;
