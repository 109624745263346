import React, { useRef } from 'react';
import { Button, Input, Modal, notification, Space, Tabs } from 'antd';
import QRCode from 'react-qr-code';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';

import { process } from '../../../env';

import { ProductSpecificationInterface } from '../ProductsPage';

interface ProductShareModalProps {
  open: boolean;
  product: ProductSpecificationInterface;
  distributorId: string;
  onComplete: () => void;
}

const ShareButton = ({
  productId,
  distributorId,
}: {
  productId: string;
  distributorId: string;
}) => {
  const text = '<a\n'
    + `  onclick="window.location='${process.env.INTEGRATOR_URL}/purchase?productId=${productId}&`
    + `distributorId=${distributorId}&returnUrl=' + btoa(window.location.href)"\n`
    + '  href="javascript:void(0)"\n'
    + '>\n'
    + '  Купить билет\n'
    + '</a>\n';
  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        Используйте код ниже для интеграции в свои страницы:
        <Input.TextArea
          rows={10}
          value={text}
        />
        <Button
          icon={<CopyOutlined />}
          onClick={async () => {
            await navigator.clipboard.writeText(text);
            notification.open({
              message: 'Код скопирован',
              description: 'Код интеграции товара скопирован в память.',
            });
          }}
        >
          Скопировать
        </Button>
      </Space>
    </div>
  );
};

const ShareTextLink = ({
  productId,
  distributorId,
}: {
  productId: string;
  distributorId: string;
}) => {
  const text = `${process.env.INTEGRATOR_URL}/purchase?productId=${productId}&distributorId=${distributorId}`;
  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        Используйте текстовую ссылку ниже для распространения:
        <Input.TextArea
          rows={4}
          value={text}
        />
        <Button
          icon={<CopyOutlined />}
          onClick={async () => {
            await navigator.clipboard.writeText(text);
            notification.open({
              message: 'Ссылка скопирована',
              description: 'Текстовая ссылка на оплату товара скопирована в память.',
            });
          }}
        >
          Скопировать
        </Button>
      </Space>
    </div>
  );
};

const ShareQRCode = ({
  productId,
  distributorId,
}: {
  productId: string;
  distributorId: string;
}) => {
  const ref = useRef(null);
  const text = `${process.env.INTEGRATOR_URL}/purchase?productId=${productId}&distributorId=${distributorId}`;
  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        Текстовая ссылка в виде QR-кода:
        <QRCode
          ref={ref}
          value={text}
          size={256}
          bgColor="white"
        />
        <Space direction="horizontal">
          <Button
            icon={<DownloadOutlined />}
            onClick={async () => {
              if (!ref.current) {
                return;
              }
              const data = new XMLSerializer().serializeToString(ref.current);
              const blob = new Blob([data], { type: 'image/svg+xml' });
              const fileUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = fileUrl;
              link.download = `QR-${productId}.svg`;
              link.click();
              link.remove();
            }}
          >
            Скачать SVG
          </Button>
          <Button
            icon={<DownloadOutlined />}
            onClick={async () => {
              if (!ref.current) {
                return;
              }
              const data = new XMLSerializer().serializeToString(ref.current);
              const blob = new Blob([data], { type: 'image/svg+xml' });
              const fileUrl = URL.createObjectURL(blob);

              const canvas = document.createElement('canvas');
              canvas.setAttribute('width', '256');
              canvas.setAttribute('height', '256');

              const image = new Image();
              image.src = fileUrl;
              image.onload = () => {
                const context = canvas.getContext('2d');
                if (context) {
                  context.drawImage(image, 0, 0);
                  const canvasdData = canvas.toDataURL('image/png');
                  const link = document.createElement('a');
                  link.href = canvasdData;
                  link.download = `QR-${productId}.png`;
                  link.click();
                  link.remove();
                }
                canvas.remove();
              };
            }}
          >
            Скачать PNG
          </Button>
        </Space>
      </Space>
    </div>
  );
};

export const ProductShareModal = ({ open, product, distributorId, onComplete }: ProductShareModalProps) => {
  const items = [
    {
      key: 'text-link',
      label: 'Текстовая ссылка',
      children: <ShareTextLink productId={product.id} distributorId={distributorId} />,
    },
    {
      key: 'qr-code',
      label: 'QR-код',
      children: <ShareQRCode productId={product.id} distributorId={distributorId} />,
    },
    {
      key: 'button',
      label: 'Кнопка',
      children: <ShareButton productId={product.id} distributorId={distributorId} />,
    },
  ];
  return (
    <Modal
      width={500}
      title="Интеграция товара"
      open={open}
      onCancel={onComplete}
      onOk={onComplete}
      footer={null}
    >
      <Tabs
        items={items}
      />
    </Modal>
  );
};
