import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const CeoInfo = ({ disabled }: Props) => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={2}>
          <Title level={3}>Сведения о руководителе</Title>
          <p>Укажите информацию о руководителе организации.</p>
        </td>
      </tr>
      <InputRow label="Фамилия" name="ceo.lastName" disabled={disabled} />
      <InputRow label="Имя" name="ceo.firstName" disabled={disabled} />
      <InputRow label="Отчество" name="ceo.middleName" disabled={disabled} />
    </tbody>
  </table>
);
