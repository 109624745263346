import React from 'react';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';

import './PurchasesPage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';
import LoadingLayout from '../application/layouts/loading-layout/LoadingLayout';

import { ProductsSummaryContainer } from './products-summary/ProductsSummaryContainer';
import createPortal from '../../utils/createPortal';

interface PurchasePageProps {
  loadPurchases: () => Promise<void>;
}

interface PurchasePageState {
  loading: boolean;
  tab: string;
}

const purchasesPageExtraTabId = 'purchases-page-extra-tab-id';
export const ExtraTabSection = createPortal(purchasesPageExtraTabId);

export class PurchasesPage extends React.Component<PurchasePageProps, PurchasePageState> {
  constructor(props: PurchasePageProps) {
    super(props);
    this.state = {
      loading: true,
      tab: 'independent',
    };
  }

  async componentDidMount() {
    const { loadPurchases } = this.props;
    await loadPurchases();
    this.setState({ loading: false });
  }

  render() {
    const { tab, loading } = this.state;
    if (loading) {
      return (
        <ApplicationLayout>
          <LoadingLayout />
        </ApplicationLayout>
      );
    }
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Организатор - Продажи</title>
        </Helmet>
        <div className="PurchasesPage">
          <Tabs
            activeKey={tab}
            onChange={(newTab) => this.setState({ tab: newTab })}
            className="clickpass-tabs"
            tabBarExtraContent={{
              right: (
                <div id={purchasesPageExtraTabId} />
              ),
            }}
            items={[
              {
                key: 'independent',
                label: 'Товары',
                children: (
                  <ProductsSummaryContainer />
                ),
              },
            ]}
          />
        </div>
      </ApplicationLayout>
    );
  }
}

export default PurchasesPage;
