import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchRegistrationPayments } from '../../../actions/registrationActions';

import { RegistrationPayments } from './RegistrationPayments';

export const RegistrationPaymentsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <RegistrationPayments
      navigate={navigate}
      onLoadRegistrationPayments={() => {
        return new Promise((resolve, reject) => {
          dispatch(fetchRegistrationPayments(resolve, reject));
        });
      }}
    />
  );
};
