import React from 'react';

import './Label.scss';

interface Props {
  title?: string;
  description?: string;
  className?: string;
}

export const Label = ({ title, description, className = '' }: Props) => (
  <div className={`Label ${className}`}>
    {
      title
      && <div className="Label-title">{title}</div>
    }
    {
      description
      && <div>{description}</div>
    }
  </div>
);

