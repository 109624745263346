import { connect } from 'react-redux';

import { BaseProductFormModal } from './BaseProductFormModal';
import { createProduct, updateProduct } from '../../../../actions/productsActions';
import { productGroupsSelector } from '../../../../selectors/productsSelectors';

const mapStateToProps = (state) => ({
  productGroups: productGroupsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCreateProduct: (product) => {
    return new Promise((resolve, reject) => {
      dispatch(createProduct(product, resolve, reject));
    });
  },
  onUpdateProduct: (id, product) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProduct(id, product, resolve, reject));
    });
  },
})

export const BaseProductFormModalContainer = connect(mapStateToProps, mapDispatchToProps)(BaseProductFormModal);

export default BaseProductFormModalContainer;
