import React, { Dispatch } from 'react';
import { thunk } from 'redux-thunk';
import { createReducer } from 'react-use';

import orderSummaryListReducer, {
  OrderSummaryListContextState,
  initialState,
  InitState,
} from './orderSummaryListReducer';

const useThunkReducer = createReducer<any, OrderSummaryListContextState>(thunk as any);

const OrderSummaryListStateContext = React.createContext<OrderSummaryListContextState>(initialState());
export const OrderSummaryListDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState?: InitState
}

const OrderSummaryListProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(orderSummaryListReducer, initialState(initState));

  return (
    <OrderSummaryListStateContext.Provider value={state}>
      <OrderSummaryListDispatchContext.Provider value={dispatch}>
        {children}
      </OrderSummaryListDispatchContext.Provider>
    </OrderSummaryListStateContext.Provider>
  );
};

function useOrderSummaryListState() {
  const context = React.useContext(OrderSummaryListStateContext);
  if (context === undefined) {
    throw new Error('useOrderSummaryListState must be used within a OrderSummaryListStateContext');
  }
  return context;
}

function useOrderSummaryListDispatch() {
  const context = React.useContext(OrderSummaryListDispatchContext);
  if (context === undefined) {
    throw new Error('useOrderSummaryListDispatch must be used within a OrderSummaryListDispatchContext');
  }
  return context;
}

export {
  OrderSummaryListProvider,
  useOrderSummaryListState,
  useOrderSummaryListDispatch,
};
