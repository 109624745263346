import { ErrorMessage } from 'formik';
import React from 'react';
import { PasswordProps } from 'antd/lib/input/Password';
import { InputField } from 'utils/form-helpers/antd-formik';

interface Props extends PasswordProps {
  label: string;
  name: string;
}

export const InputPasswordRow = ({ label, name, ...other }: Props) => (
  <>
    <tr>
      <td className="label-column">{`${label} :`}</td>
      <td>
        <InputField.Password name={name} {...other} />
      </td>
    </tr>
    <tr>
      <td />
      <td className="error-column">
        <ErrorMessage name={name}>{(error) => error}</ErrorMessage>
        &nbsp;
      </td>
    </tr>
  </>
);

export default InputPasswordRow;
