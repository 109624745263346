import { SET_PRODUCT_GROUPS } from "../actions/productGroupsActions";

const initialState = {
  list: []
};

export const productGroups = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_GROUPS: {
      return {
        ...state,
        list: [
          ...action.payload.productGroups
        ]
      };
    }

    default:
      return state;
  }
};
