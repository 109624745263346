export const ProductItemStatus = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  CONSUMED: 'CONSUMED',
  PARTLY_CONSUMED: 'PARTLY_CONSUMED',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
};

export const ProductItemStatusText = {
  [ProductItemStatus.NEW]: 'Создан',
  [ProductItemStatus.CONFIRMED]: 'Оплачен',
  [ProductItemStatus.CONSUMED]: 'Погашен',
  [ProductItemStatus.PARTLY_CONSUMED]: 'Погашен част.',
  [ProductItemStatus.CANCELED]: 'Отменён',
  [ProductItemStatus.REFUNDED]: 'Возвращен',
};
