export const CREATE_PRODUCT_GROUP = 'CREATE_PRODUCT_GROUP';
export const createProductGroup = (productGroup, resolve, reject) => ({
  type: CREATE_PRODUCT_GROUP,
  payload: {
    productGroup,
  },
  meta: {
    resolve,
    reject,
  },
});

export const UPDATE_PRODUCT_GROUP = 'UPDATE_PRODUCT_GROUP';
export const updateProductGroup = (id, productGroup, resolve, reject) => ({
  type: UPDATE_PRODUCT_GROUP,
  payload: {
    id,
    productGroup,
  },
  meta: {
    resolve,
    reject,
  },
});

export const DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP';
export const deleteProductGroup = (id, resolve, reject) => ({
  type: DELETE_PRODUCT_GROUP,
  payload: {
    id,
  },
  meta: {
    resolve,
    reject,
  },
});

export const SET_PRODUCT_GROUPS = 'SET_PRODUCT_GROUPS';
export const setProductGroups = (productGroups) => ({
  type: SET_PRODUCT_GROUPS,
  payload: {
    productGroups,
  },
})