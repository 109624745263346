import { ErrorMessage } from 'formik';
import React from 'react';
import { SelectProps } from 'antd';
import { SelectField } from 'utils/form-helpers/antd-formik';

interface Props extends SelectProps {
  label: string;
  name: string;
}

export const SelectRow = ({ label, name, options, ...other }: Props) => (
  <>
    <tr>
      <td className="label-column">{`${label} :`}</td>
      <td>
        <SelectField name={name} options={options} {...other} />
      </td>
    </tr>
    <tr>
      <td />
      <td className="error-column">
        <ErrorMessage name={name}>{(error) => error}</ErrorMessage>
        &nbsp;
      </td>
    </tr>
  </>
);

export default SelectRow;
