import { InputProps } from 'antd';
import { ErrorMessage } from 'formik';
import React from 'react';
import { InputField, CheckboxField } from 'utils/form-helpers/antd-formik';

interface Props extends InputProps {
  label: string;
  name: string;
}

export const InputRow = ({ label, name, ...other }: Props) => (
  <>
    <tr>
      <td className="label-column">{`${label} :`}</td>
      <td>
        <InputField name={name} {...other} />
      </td>
      <td className="check-column">Проверено :</td>
      <td className="check-input-column">
        <CheckboxField name={`${name}Verified`} />
      </td>
    </tr>
    <tr>
      <td />
      <td colSpan={3} className="error-column">
        <ErrorMessage name={name}>{(error) => error}</ErrorMessage>
        <ErrorMessage name={`${name}Verified`}>{(error) => error}</ErrorMessage>
        &nbsp;
      </td>
    </tr>
  </>
);

export default InputRow;
