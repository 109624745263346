import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';
import { SelectRow } from '../fields/SelectRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const PhonesInfo = ({ disabled }: Props) => {
  const phoneTypeOptions = [
    {
      label: 'Основной',
      value: 'common',
    }, {
      label: 'Факс',
      value: 'fax',
    }, {
      label: 'Другой',
      value: 'other',
    },
  ];
  return (
    <table style={{ maxWidth: '700px', width: '100%' }}>
      <tbody>
        <tr>
          <td colSpan={2}>
            <Title level={3}>Телефоны для связи</Title>
            <p>Укажите телефон организации. Этот телефон может быть передан клиенту для общения с поддержкой.</p>
          </td>
        </tr>
        <SelectRow label="Тип" name="phones[0].type" options={phoneTypeOptions} disabled={disabled} />
        <InputRow label="Номер" name="phones[0].phone" disabled={disabled} />
        <tr>
          <td colSpan={2}>
            <p>Укажите телефон контактного лица. Этот телефон может быть использован нами для связи с вами.</p>
          </td>
        </tr>
        <SelectRow label="Тип" name="phones[1].type" options={phoneTypeOptions} disabled={disabled} />
        <InputRow label="Номер" name="phones[1].phone" disabled={disabled} />
      </tbody>
    </table>
  );
};
