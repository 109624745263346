import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import { fetchAccounts } from '../../actions/accountsActions';
import { accountsSelector } from '../../selectors/accountsSelectors';

import PartnersPage from "./PartnersPage";

const PartnersPageContainer = () => {
  const partners = useSelector(accountsSelector);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  return (
    <PartnersPage
      navigate={navigate}
      params={params}
      partners={partners}
      onLoadPartners={() => {
        dispatch(fetchAccounts());
      }}
    />
  )
}

export {
  PartnersPageContainer
}