import React from 'react';
import ReactDOM from 'react-dom';

interface CreatePortalPropTypes {
  portalRootElementId: string;
  className?: string;
  visible?: boolean;
  children?: React.ReactNode;
}

class CreatePortal extends React.PureComponent<CreatePortalPropTypes> {
  portalElement: HTMLDivElement;

  portalRoot: HTMLElement | null;

  private appendChild: boolean = false;

  static defaultProps = {
    className: undefined,
    visible: true,
  };

  constructor(props: CreatePortalPropTypes) {
    super(props);
    const { portalRootElementId, className } = props;
    this.portalElement = document.createElement('div');
    if (className) {
      className.split(' ')
        .forEach((itClassName) => {
          this.portalElement.classList.add(itClassName);
        });
    }
    this.portalRoot = document.getElementById(portalRootElementId);
    this.appendPortalElement = this.appendPortalElement.bind(this);
  }

  componentDidMount() {
    this.appendPortalElement();
  }

  componentDidUpdate() {
    this.appendPortalElement();
  }

  componentWillUnmount() {
    if (this.appendChild) {
      this.portalRoot?.removeChild(this.portalElement);
      this.appendChild = false;
    }
  }

  appendPortalElement() {
    if (!this.portalRoot) {
      const { portalRootElementId } = this.props;
      this.portalRoot = document.getElementById(portalRootElementId);
    }
    if (!this.appendChild) {
      this.portalRoot?.appendChild(this.portalElement);
      this.appendChild = true;
    }
  }

  render() {
    const { children, visible } = this.props;
    if (visible) {
      return ReactDOM.createPortal(children, this.portalElement) as React.ReactNode;
    }
    return null;
  }
}

interface CreatePortalHOCProps {
  className?: string;
  children?: any;
}
export const createPortal = (elementId: string) => {
  const Component = ({ className, children }: CreatePortalHOCProps) => (
    <CreatePortal className={className} portalRootElementId={elementId}>
      {children}
    </CreatePortal>
  );
  Component.displayName = 'PortalElement';
  return Component;
};

export default createPortal;
