import React, { useCallback } from 'react';
import { Modal, notification } from 'antd';

import SignupPage from './SignupPage';
import { SignupProvider, useSignupDispatch, useSignupState } from './context/signupContext';
import { signUp } from './context/signupThunks';
import { setBankAccountInfo, setBeneficiaryInfo, setMainInfo, setStep, SignupStep } from './context/signupReducer';
import {
  bankAccountInfoSelector,
  beneficiaryInfoSelector,
  mainInfoSelector,
  stepSelector,
} from './context/signupSelectors';
import { Account, BankAccount, IpBeneficiary, UlBeneficiary } from './form';

const SignupPageContainer = () => {
  const dispatch = useSignupDispatch();
  const state = useSignupState();
  return (
    <SignupPage
      step={stepSelector(state)}
      accountValues={mainInfoSelector(state)}
      beneficiaryValues={beneficiaryInfoSelector(state)}
      bankAccountValues={bankAccountInfoSelector(state)}
      onAccountValueUpdate={useCallback((values: Account) => {
        dispatch(setMainInfo(values));
      }, [dispatch])}
      onBeneficiaryInfoUpdate={useCallback((values: UlBeneficiary | IpBeneficiary) => {
        dispatch(setBeneficiaryInfo(values));
      }, [dispatch])}
      onBankAccountUpdate={useCallback((values: BankAccount) => {
        dispatch(setBankAccountInfo(values));
      }, [dispatch])}
      onSignUp={useCallback(async (values: any, { setSubmitting, setErrors }: {
        setSubmitting: any,
        setErrors: any
      }) => {
        try {
          await dispatch(signUp);
          Modal.info({
            title: 'Регистрация в системе ClickPass',
            content: (
              <div>Ваша заявка принята</div>
            ),
            onOk() {
              window.location.href = '/';
            },
          });
        } catch (error) {
          setErrors(error);
          notification.success({ message: 'Что-то пошло не так.' });
        }
        setSubmitting(false);
      }, [])}
      onUpdateStep={useCallback((step: SignupStep) => {
        dispatch(setStep(step));
      }, [])}
    />
  );
};

export const SignupPageContainerWrapper = () => (
  <SignupProvider>
    <SignupPageContainer />
  </SignupProvider>
);

export default SignupPageContainerWrapper;
