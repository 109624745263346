import { connect } from 'react-redux';

import { PlainProductFormModal } from './PlainProductFormModal';
import { createProduct, updateProduct } from '../../../../actions/productsActions';
import { productGroupsSelector } from '../../../../selectors/productsSelectors';

const mapStateToProps = (state) => ({
  productGroups: productGroupsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCreateProduct: (product) => {
    return new Promise((resolve, reject) => {
      dispatch(createProduct(product, resolve, reject));
    });
  },
  onUpdateProduct: (id, product) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProduct(id, product, resolve, reject));
    });
  },
})

export const PlainProductFormModalContainer = connect(mapStateToProps, mapDispatchToProps)(PlainProductFormModal);

export default PlainProductFormModalContainer;
