export const getDateTimeString = (productItem: any) => {
  let dateString = '';
  const { date, startTime, endTime } = productItem.item;
  if (date) {
    dateString += `Дата: ${date}`;
  }
  if (startTime && !endTime) {
    dateString += ` Время: ${startTime}`;
  }
  if (startTime && endTime) {
    dateString += ` Время: с ${startTime} до ${endTime}`;
  }
  return dateString;
};