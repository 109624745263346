type FetchResponse = Awaited<ReturnType<typeof fetch>>;
export class ApiError extends Error {
  private _response: FetchResponse;

  constructor(response: FetchResponse) {
    super('Api error');
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
    this._response = response;
  }

  public get response() {
    return this._response;
  }
}
