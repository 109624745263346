import React from 'react';
import { Space, Table } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import dayjs from 'dayjs';

const columns = [
  {
    title: 'Создан',
    dataIndex: [ 'tinkoffShop', 'created' ],
    width: '160px',
    sorter: (a, b) => {
      return dayjs(a?.tinkoffShop?.created).valueOf() - dayjs(b?.tinkoffShop?.created).valueOf()
    },
    render: (value) => {
      if (value) {
        return dayjs(value).format('DD/MM/YYYY HH:mm');
      } else {
        return '';
      }
    },
  }, {
    title: 'Название',
    dataIndex: 'name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
  }, {
    title: 'Точка Орг.',
    dataIndex: 'tinkoffShopCode',
    width: '160px',
    sorter: (a, b) => (parseInt(a.tinkoffShopCode, 10) > parseInt(b.tinkoffShopCode, 10) ? 1 : -1),
  }, {
    title: 'Точка Распр.',
    dataIndex: 'tinkoffDistributorShopCode',
    width: '160px',
    sorter: (a, b) => (parseInt(a.tinkoffShopCode, 10) > parseInt(b.tinkoffShopCode, 10) ? 1 : -1),
  }, {
    dataIndex: 'id',
    width: '80px',
    render: (id, record) => {
      return <React.Fragment>
        <Space>
        </Space>
      </React.Fragment>;
    },
  },
];

const PartnersList = ({ partners }) => {
  return <Table

    columns={columns}
    dataSource={partners}
    pagination={false}
    rowKey="id"
    showSorterTooltip={false}
    expandable={{
      expandedRowRender: (record) => (
        <SyntaxHighlighter language="json">
          {JSON.stringify(record.tinkoffShop, null, 2)}
        </SyntaxHighlighter>
      ),
    }}
    style={{ width: '100%' }}
    // TODO: Косячит переключение в полноэкранный режим
    scroll={{ x: '100%', y: 'calc(100vh - 142px)' }}
  />
}

export default PartnersList;