import React from 'react';
import { Button, Radio, Space } from 'antd';

import './MultiTicket.scss';

import { ProductItemStatus } from "../../../../constants/productItem";

export class MultiTicket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpecificationId: undefined,
    }
    this.renderSpecificationSelection = this.renderSpecificationSelection.bind(this);
    this.getAvailableConsumptions = this.getAvailableConsumptions.bind(this);
    this.initSelectedSpecification = this.initSelectedSpecification.bind(this);
  }

  componentDidMount() {
    this.initSelectedSpecification();
  }

  componentDidUpdate() {
    this.initSelectedSpecification();
  }

  initSelectedSpecification() {
    const availableConsumptions = this.getAvailableConsumptions();
    if (availableConsumptions.length === 1) {
      this.setState({ selectedSpecificationId: availableConsumptions[0].id })
    }
  }

  getAvailableConsumptions() {
    const { accountId, productItem } = this.props;
    return productItem.consumption.productSpecifications.filter((consumption) => {
      const specification = productItem.specification.components.find((c) => c.id === consumption.id);
      return specification?.accountId === accountId && consumption.consumed === false;
    });
  }

  renderSpecificationSelection() {
    const { productItem } = this.props;
    const { selectedSpecificationId } = this.state;
    const availableConsumptions = this.getAvailableConsumptions();
    return (
      <div className="MultiTicket-specificationSelect">
        <div className="MultiTicket-specificationSelectLabel">Выберите билет:</div>
        <Radio.Group
          onChange={(event) => {
            this.setState({ selectedSpecificationId: event.target.value });
          }}
          value={selectedSpecificationId}
        >
          <Space direction="vertical">
            {
              availableConsumptions.map((consumption) => {
                const specification = productItem.specification.components.find((c) => c.id === consumption.id)
                return (
                  <Radio key={specification.id} value={specification.id}>{specification.title}</Radio>
                )
              })
            }
          </Space>
        </Radio.Group>
      </div>
    );
  }

  render() {
    const { productItem, onCancel, onConsume } = this.props;
    const { selectedSpecificationId } = this.state;
    const categoryName = productItem.specification.components[0].categories.find(c => c.id === productItem.item.categoryId).name;
    const title = `${productItem.specification.title} (${categoryName})`;
    const availableConsumptions = this.getAvailableConsumptions();

    return <div>
      <b>
        {title}<br />
        {productItem.specification.description}<br />
        <small>{productItem.item.price} р.</small>
      </b>
      {
        availableConsumptions.length > 1
        && this.renderSpecificationSelection()
      }
      <br />
      {
        availableConsumptions.length === 1
        && (
          <>
            <div>{productItem.specification.components.find((c) => c.id === availableConsumptions[0].id)?.title}</div>
            <div>Не погашен</div>
          </>
        )
      }
      {availableConsumptions.length === 0 && <div>Погашен</div>}
      {productItem.status === ProductItemStatus.CANCELED && <div>Оплата отменена</div>}
      {productItem.status === ProductItemStatus.REFUNDED && <div>Возмещен</div>}
      <br />
      <Space>
        {
          availableConsumptions.length > 0 &&
          <Button
            onClick={async () => {
              await onConsume(selectedSpecificationId);
              this.setState({ selectedSpecificationId: undefined });
            }}
            disabled={!selectedSpecificationId}
          >
            Погасить
          </Button>
        }
        <Button onClick={onCancel}>Сканировать билет</Button>
      </Space>
    </div>;
  }
}

export default MultiTicket;
