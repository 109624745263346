import React from 'react';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';

import './OrdersPage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';
import LoadingLayout from '../application/layouts/loading-layout/LoadingLayout';
import { SummaryTableContainer } from './summary-table/SummaryTableContainer';
import createPortal from '../../utils/createPortal';

const ordersPageExtraTabId = 'orders-page-extra-tab-id';
export const ExtraTabSection = createPortal(ordersPageExtraTabId);

interface OrdersPageProps {
  loadOrders: () => Promise<any>;
}

interface OrdersPageState {
  loading: boolean;
}

export default class OrdersPage extends React.Component<OrdersPageProps, OrdersPageState> {
  constructor(props: OrdersPageProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { loadOrders } = this.props;
    await loadOrders();
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <ApplicationLayout>
          <LoadingLayout />
        </ApplicationLayout>
      );
    }

    return (
      <ApplicationLayout>
        <Helmet>
          <title>Распространитель - Продажи</title>
        </Helmet>
        <div className="OrdersPage">
          <Tabs
            className="clickpass-tabs"
            tabBarExtraContent={{
              right: (
                <div id={ordersPageExtraTabId} />
              ),
            }}
            items={[
              {
                key: 'transactions',
                label: 'Транзакции',
                children: <SummaryTableContainer />,
              },
            ]}
          />
        </div>
      </ApplicationLayout>
    );
  }
}
